import React, { useState } from 'react'
import { useAuthContext } from '../hooks/useAuthContext'
import axios from '../api/axios';
import { useLogout } from '../hooks/useLogout';
import { useNavigate } from 'react-router-dom';


function Forgotpass() {

    const {user} = useAuthContext();
    const [currPass, setCurrPass] = useState("");
    const [newPass, setNewPass] = useState("");
    const [confirmNewPass, setConfirmNewPass] = useState("");
    const [succ, setSucc] = useState("");
    const [err, setErr] = useState("");

  return (
    <div className='App'>
    <div className="container mt-3">
      <div className="row justify-content-center text-center">
        <div className="col-lg-12">
        <h1 className="my-4 header-title">FORGOT PASSWORD</h1>
        </div>
        <div class="row justify-content-center text-start mt-3">
  <div class="col-sm-6">
  {succ && (<div class="alert alert-success mb-4" role="alert">
                    {succ}
                    </div>)}
                    {err && (<div class="alert alert-danger mb-4" role="alert">
                    {err}
                    </div>)}
    <div className='card'>
      <div className='card-body'>
      <form>
      <div class="form-floating mb-2">
        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" />
        <label for="floatingInput">Email address</label>
        </div>
        <div className="d-grid gap-2 mb-4">
            <button className="btn btn-primary mt-3" type='submit'>Submit Email</button>
          </div>
        </form>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default Forgotpass