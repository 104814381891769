import React from 'react'

function Mchat_design() {
  return (
    <div className='App'>
      <div className="container mt-3">
        <div className="row justify-content-center">
          <div className="col-lg-12 text-center">
            <h1 className="mt-4 mb-2 header-title">MCHAT</h1>
            </div>
            </div>
            </div>
            </div>
  )
}

export default Mchat_design