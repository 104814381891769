import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import RegisterTextField from './RegisterTextField';
import * as Yup from 'yup';
import axios from '../../api/axios';
import { REGISTER_URL } from '../../api/url';
import { useAuthContext } from '../../hooks/useAuthContext';
import { Link } from 'react-router-dom';

function RegisterPage() {

    const initValues = {


        username: "",
        password: "",
        confirmPassword: "",
        email: "",
        phoneNumber: "",
    }

    const validate = Yup.object({
        username: Yup.string().matches(/^\S*$/, "Spaces are not allowed in username").max(15, "Username must be 15 characters and lower").required("Username is required"),
        email: Yup.string().email("Please insert legit email").required("Email is required"),
        password: Yup.string().min(6, "Your password is too short, must be 6 characters and above").required("Please enter a Password"),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], "Password does not match").required("Please enter Password confirmation"),
        phoneNumber: Yup.string().required("Mobile Number is required")
    })

    const {dispatch} = useAuthContext();
    const [errMsg, setErrMsg] = useState("");

    const onSubmit = (values) =>{
        axios.post(REGISTER_URL, values, []).then ( async (response) =>{
            const json = await response.data;
            if (response.data.error) {
                setErrMsg(response.data.error)
              }else if (!response.data.token2){
                localStorage.setItem("accessToken", JSON.stringify(json))
                dispatch({type: 'LOGIN', payload: json})
              }else{
                localStorage.setItem("accessToken", JSON.stringify(json))
                dispatch({type: 'LOGIN', payload: json})
              }
        })
    }

  return (
    <Formik initialValues={initValues} validationSchema={validate} onSubmit={onSubmit} >
        {formik => (
            <div className='card mt-4'>
                <div className="card-body">
                <h1 className="my-4 header-title text-center">SIGN UP</h1>
                {!errMsg ?(<></>):(
                    <>
                <div class="alert alert-danger" role="alert">
                    {errMsg}
                </div>
                    </>
                )}
                <Form className='mb-3'>
                    <RegisterTextField label="Username" name="username" type="text" />
                    <RegisterTextField label="Password" name="password" type="password" />
                    <RegisterTextField label="Re-type Password" name="confirmPassword" type="password" />
                    <RegisterTextField label="Email" name="email" type="email" />
                    <RegisterTextField label="Mobile Number" name="phoneNumber" type="text" placeholder="e.g: 010337****" />
                    <div class="d-grid gap-2 mt-4">
                    <button className="btn btn-success" type="submit">Register Now</button>
                    </div>
                    <div class="d-grid gap-2 mt-3">
                    <button className="btn btn-outline-secondary btn-sm" type="reset">Reset</button>
                    </div>
                    <p className='my-4 text-center'>Already Have An Account? | <Link className='link' to='/login'>Sign In Now!</Link></p>
                </Form>
                </div>


            </div>
        )}
    </Formik>
  )
}

export default RegisterPage