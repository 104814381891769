import React, { useEffect, useState } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext';
import axios, { msmartAxios } from '../../api/axios';
import { Link, useNavigate, useParams } from 'react-router-dom';

function ManageLeads() {

  const {user} = useAuthContext();
  const {teamName} = useParams();
  const [dbData, setDbData] = useState([]);
  const [error, setError] = useState("");
  const [createError, setCreateError] = useState("");
  const [editError, setEditError] = useState("");
  const [search, setSearch] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [editDBId, setEditDBId] = useState(0);
  const [deleteDbId, setDeleteDbId] = useState(0);
  const navigate = useNavigate();
  const [searchState, setSearchState] = useState("")


  //create
  const [createName, setCreateName] = useState("");
  const [createMobile, setCreateMobile] = useState("");
  const [createStatus, setCreateStatus] = useState("");
  const [createRemarks, setCreateRemarks] = useState("");
  const [createFollowUp, setCreateFollowUp] = useState("");

  //edit
  const [editId, setEditId] = useState("");
  const [editName, setEditName] = useState("");
  const [editMobile, setEditMobile] = useState("");
  const [editStatus, setEditStatus] = useState("");
  const [editRemarks, setEditRemarks] = useState("");
  const [editFollowUp, setEditFollowUp] = useState("");


  useEffect(() => {
    msmartAxios.get(`/api/msmart/leads/all/${teamName}`, {headers: {
      accessToken: user.token
    }}).then((response) => {
      const resdata = response.data;
      if(resdata.error){
        setError(resdata.error)
      }else{
        setDbData(resdata)
      }
      
    })
  }, [])


  const createDb = (e) => {
    e.preventDefault()
    const data = {team:teamName, name: createName, phone: createMobile, status: createStatus, remarks: createRemarks, followUp: createFollowUp}
    msmartAxios.post(`/api/msmart/lead`, data, {headers: {
      accessToken:user.token
    }}).then((response) => {
      if(response.data.status){
        window.location.reload()
      }
      if(response.data.error){
        setCreateError(response.data.error)
      }
    }).catch((err) => {
      setEditError("No response from server, please try again")
    })
  }

  console.log(new Date(editFollowUp.toString()))

  const deleteDB = () => {
    
  }

  const cancelDelete = () => {
    setShowAlert(false);
    setDeleteDbId(0);
  }

  const editSingle = (id) => {
    msmartAxios.get(`/api/msmart/lead/${id}`, {headers: {
      accessToken: user.token
    }}).then((response) => {
      const json = response.data.db
      if(json){
        console.log(json)
        setEditId(json.id);
        setEditName(json.name);
        setEditMobile(json.phone);
        setEditStatus(json.status);
        setEditRemarks(json.remark);
        setEditFollowUp(new Date(json.followUpDate.toString()));
      }
    }).catch((err) => {
      setEditError("No response from server, please try again")
    })
  }

  const submitEdit = (e) => {
    e.preventDefault();
    const data = {name: editName, phone: editMobile, status: editStatus, remark: editRemarks, followUp: editFollowUp}
    msmartAxios.put(`/api/msmart/lead/${teamName}/${editId}`, data, {headers: {
      accessToken: user.token
    }}).then((response) => {
      if(response.data.error){
        setEditError(response.data.error);
      }else if(response.data.success){
        window.location.reload()
      }
    }).catch((err) => {
      setEditError("No response from server, please try again")
    })
  }


  return (
    <div className='App'>
    <div className="container mt-3">
      <div className="row justify-content-center text-center">
        <div className="col-lg-12">
        <h1 className="mt-4 header-title">M-SMART</h1>
        <p style={{fontSize:"1rem"}}>No more 1000 files on your desk and desktop. Say hello to M-Smart 😎</p>
        </div>
        </div>
        </div>
        
        
        <div className='row justify-content-center mt-3'>
          <div className='col-lg-6 text-center'>
            <div className='container'>
            {!error ? (<></>) : (
            <div class="alert alert-danger text-center" role="alert">
              {error}
            </div>)}
            
           
            
            <div className='text-end mx-1'><button className='btn btn-sm btn-success' data-bs-toggle="modal" data-bs-target="#createDB">+New Database</button></div>
            <div className="row">
              <div className="col-3">
              <select className='form-control shadow-none my-3'>
                <option value=''>search by..</option>
                <option value='name'>Name</option>
                <option value='phone'>Phone Number</option>
                <option value='status'>Status</option>
              </select>
              </div>
              <div className="col">
              <input className='form-control shadow-none my-3' type='text' onChange={(event) =>{setSearch(event.target.value)}} />
              </div>
            </div>
            
            
              
                <div className='card'>
                  <div className='table-responsive'>
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Phone No.</th>
                        <th scope="col">Status</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    {dbData.filter((value) => {
                        return search.toLowerCase() === '' ? value : value.phone.toLowerCase().includes(search);
                    }).map((value, key) => {

                      

                      return(
                        <tbody>
                      <tr>
                        <th scope="row">{key+1}</th>
                        <td>{value.name}</td>
                        <td>{value.phone}</td>
                        <td>{value.status}</td>
                        <td>
                          <div className='d-flex gap-2 justify-content-center'><a className='btn btn-sm btn-success' href={`https://api.whatsapp.com/send?phone=${value.phone}`}><i class="bi bi-whatsapp"></i></a> <button className='btn btn-sm btn-primary' onClick={() => {editSingle(value.id)}} data-bs-toggle="modal" data-bs-target="#EditDB"><i class="bi bi-pencil-fill"></i></button> <button className='btn btn-sm btn-danger' onClick={() => {setDeleteDbId(value.id)}} data-bs-toggle="modal" data-bs-target="#deleteDB">X</button></div></td>
                      </tr>
                    </tbody>
                      )
                    })}
                    
                  </table>
                  </div>
                </div>


<div class="modal fade" id="deleteDB" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content yellow-100" >
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h5>Are you sure you want to delete this database?</h5>
        <br/>
        <button type="button" class="btn btn-sm btn-secondary mx-1" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-sm btn-danger mx-1" onClick={{deleteDB}}>Delete</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="EditDB" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel"><strong>Edit Database</strong></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">

      {!editError ? (<></>) : (
            <div class="alert alert-danger text-center" role="alert">
              {editError}
            </div>)}

      <form onSubmit={submitEdit}>

      <div class="mb-3 text-start">
        <label className='mx-1'>Name</label>
        <input type="text" class="form-control" value={editName} onChange={(event) => {setEditName(event.target.value)}} required />
      </div>

      <div class="mb-3 text-start">
        <label className='mx-1'>Phone Number</label>
        <input type="number" class="form-control" value={editMobile}  onChange={(event) => {setEditMobile(event.target.value)}} required />
        <p style={{fontSize:'80%', color:'grey'}}>Include country code, eg: 601123.....</p>
      </div>

      <div class="mb-3 text-start">
        <label className='mx-1'>Status</label>
        <select type="text" class="form-control" onChange={(event) => {setEditStatus(event.target.value)}} required>
          <option value={editStatus}>{editStatus}</option>
          <option value="Rejected">Rejected</option>
          <option value="Closed">Closed</option>
        </select>
      </div>

      <div class="mb-3 text-start">
        <label className='mx-1'>Remarks</label>
        <textarea type="text" class="form-control" value={editRemarks}  onChange={(event) => {setEditRemarks(event.target.value)}} />
      </div>

      <div class="mb-3 text-start">
        <label className='mx-1'>Follow Up Date</label>
        <input type="datetime-local" class="form-control" defaultValue={editFollowUp} onChange={(event) => {setEditFollowUp(event.target.value)}} />
        <p>Current Follow Up Date: <strong>{editFollowUp.toString().substr(4,11)}</strong></p>
      </div>

      <div className='d-flex gap-2 justify-content-center'><button type="submit" class="btn btn-primary mx-5 my-3">Save changes</button></div>
      
      </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="createDB" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel"><strong>New Database</strong></h1>
        <button id='closeCreate' type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">

      {!createError ? (<></>) : (
            <div class="alert alert-danger text-center" role="alert">
              {createError}
            </div>)}

      <form onSubmit={createDb}>

      <div class="mb-3 text-start">
        <label className='mx-1'>Name</label>
        <input type="text" class="form-control" onChange={(event) => {setCreateName(event.target.value)}} required />
      </div>

      <div class="mb-3 text-start">
        <label className='mx-1'>Phone Number</label>
        <input type="number" class="form-control" placeholder='601233...'  onChange={(event) => {setCreateMobile(event.target.value)}} required />
        <p style={{fontSize:'80%'}}>Include country code, eg: 601123.....</p>
      </div>

      <div class="mb-3 text-start">
        <label className='mx-1'>Status</label>
        <select type="text" class="form-control"  onChange={(event) => {setCreateStatus(event.target.value)}} required>
          <option value=''></option>
          <option value="Rejected">Rejected</option>
          <option value="Closed">Closed</option>
        </select>
      </div>

      <div class="mb-3 text-start">
        <label className='mx-1'>Remarks</label>
        <textarea type="text" class="form-control"  onChange={(event) => {setCreateRemarks(event.target.value)}} />
      </div>

      <div class="mb-3 text-start">
        <label className='mx-1'>Follow Up Date</label>
        <input type="datetime-local" class="form-control"  onChange={(event) => {setCreateFollowUp(event.target.value)}} />
      </div>

      <div className='d-flex gap-2 justify-content-center'><button type="submit" class="btn btn-primary mx-5 my-3">Add New Database</button></div>
      
      </form>

      </div>
        
    </div>
  </div>
</div>

            </div>
            </div>
          </div>
        
        
        </div>
  )
}

export default ManageLeads