export  
const lineData = [
    {
        day: "1/12",
        prospecting: 4,
        connect: 5,
        enggage: 12,
        result: 0
    },
    {
        day: "2/12",
        prospecting: 5,
        connect: 8,
        enggage: 15,
        result: 1
    },
    {
        day: "3/12",
        prospecting: 5,
        connect: 3,
        enggage: 6,
        result: 0
    },
    {
        day: "4/12",
        prospecting: 3,
        connect: 8,
        enggage: 21,
        result: 2
    },
    {
        day: "5/12",
        prospecting: 12,
        connect: 14,
        enggage: 26,
        result: 1
    },
    {
        day: "6/12",
        prospecting: 4,
        connect: 5,
        enggage: 12,
        result: 0
    },
    {
        day: "7/12",
        prospecting: 5,
        connect: 8,
        enggage: 15,
        result: 1
    },
    {
        day: "8/12",
        prospecting: 5,
        connect: 3,
        enggage: 6,
        result: 0
    },
    {
        day: "9/12",
        prospecting: 3,
        connect: 8,
        enggage: 21,
        result: 2
    },
    {
        day: "10/12",
        prospecting: 12,
        connect: 14,
        enggage: 26,
        result: 1
    },
    {
        day: "11/12",
        prospecting: 4,
        connect: 5,
        enggage: 12,
        result: 0
    },
    {
        day: "12/12",
        prospecting: 5,
        connect: 8,
        enggage: 15,
        result: 1
    },
    {
        day: "13/12",
        prospecting: 5,
        connect: 3,
        enggage: 6,
        result: 0
    },
    {
        day: "14/12",
        prospecting: 3,
        connect: 8,
        enggage: 21,
        result: 2
    },
    {
        day: "15/12",
        prospecting: 12,
        connect: 14,
        enggage: 26,
        result: 1
    },
    {
        day: "16/12",
        prospecting: 12,
        connect: 14,
        enggage: 26,
        result: 1
    },
    {
        day: "17/12",
        prospecting: 12,
        connect: 14,
        enggage: 26,
        result: 1
    },
    {
        day: "18/12",
        prospecting: 12,
        connect: 14,
        enggage: 26,
        result: 1
    },
    {
        day: "19/12",
        prospecting: 12,
        connect: 14,
        enggage: 26,
        result: 1
    },
    {
        day: "20/12",
        prospecting: 12,
        connect: 14,
        enggage: 26,
        result: 1
    },
    {
        day: "21/12",
        prospecting: 12,
        connect: 14,
        enggage: 26,
        result: 1
    },
    {
        day: "22/12",
        prospecting: 12,
        connect: 14,
        enggage: 26,
        result: 1
    },
    {
        day: "23/12",
        prospecting: 12,
        connect: 14,
        enggage: 26,
        result: 1
    },
    {
        day: "24/12",
        prospecting: 12,
        connect: 14,
        enggage: 26,
        result: 1
    },
    {
        day: "25/12",
        prospecting: 12,
        connect: 14,
        enggage: 26,
        result: 1
    },
    {
        day: "26/12",
        prospecting: 12,
        connect: 14,
        enggage: 26,
        result: 1
    },
    {
        day: "27/12",
        prospecting: 12,
        connect: 14,
        enggage: 26,
        result: 1
    },
    {
        day: "28/12",
        prospecting: 12,
        connect: 14,
        enggage: 26,
        result: 1
    },
    {
        day: "29/12",
        prospecting: 12,
        connect: 14,
        enggage: 26,
        result: 1
    },
    {
        day: "30/12",
        prospecting: 12,
        connect: 14,
        enggage: 26,
        result: 1
    },
];