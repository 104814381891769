import React, { useEffect, useRef, useState } from 'react';
import './chat.css'
import io from 'socket.io-client';
import { useNavigate, useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";
import ReactPlayer from 'react-player'
import defaultPic from '../../components/images/default_pic_mchat.png'
import { mchatAxios } from '../../api/axios';
import { CookiesProvider, useCookies } from 'react-cookie';
import sendNotif from '../../components/sounds/send.mp3'
import receiveNotif from '../../components/sounds/receive.mp3'


const socket = io.connect(process.env.REACT_APP_MCHAT);

function Mchat_Link({setNavbar, setFoo, children }) {

  useEffect(() => {
    setNavbar(false);
    setFoo(false)
})


  const {chatId} = useParams();
  const chatBoxRef = useRef(null);
  const [cookies, setCookie, removeCookie] = useCookies(['user']);

  const sendSound = new Audio(sendNotif)
  const receiveSound = new Audio(receiveNotif)

  //Chat
  const [profilePic, setProfilePic] = useState("");
  const [botName, setBotName] = useState("");
  const [saveMessage, setSaveMessage] = useState("");

  //welcome
  const [getStartFlow, setGetStartFlow] = useState(null);
  const [salutationField, setSalutationField] = useState(null);
  const [nameField, setNameField] = useState("");
  const [name, setName] = useState("");
  const [salutation, setSalutation] = useState("");
  const [getStart, setGetStart] = useState(null);
  const [startErr, setStartErr] = useState("");
  const [getStartButton, setGetStartButton] = useState(true)

  //Flow
  const [flow, setFlow] = useState([]);
  const [currentFlow, setCurrentFlow] = useState(null)
  const [messages, setMessages] = useState([]);
  const [inputValues, setInputValues] = useState("");
  const [showInputBox, setShowInputBox] = useState(true);
  const [showGrid, setShowGrid] = useState(false);
  const [showDivItem, setShowDivItem] = useState(false);
  const [divs, setDivs] = useState([]);
  const inputRef = useRef(null);
  const flowRef = useRef([]);
  const currentFlowRef = useRef(0);
  const [imgPop, setImgPop] = useState("");

  //design
  const [bg1,setBg1] = useState("rgba(255, 255, 255, 1)");
  const [bg2,setBg2] = useState("rgba(255, 255, 255, 1)");
  const [userBg, setUserBg] = useState("#0027b5")
  const [flowBtn, setFlowBtn] = useState("#000c36")
  const [contactBtn, setContactBtn] = useState("#000c36")
  const [inputBtn, setInputBtn] = useState("#000c36")
  const [paddingTop, setPaddingTop] = useState('0px');




  useEffect(() => {
    mchatAxios.get(`/api/mchat/public/chat/design/${chatId}`).then((response) =>{
      if(response.status === 200){

        let image;

        if(response.data.image === 'null'){
          image = defaultPic
          setProfilePic(image)
          setBotName(response.data.botName);
          setBg1(response.data.design.bg1)
          setBg2(response.data.design.bg2)
          setUserBg(response.data.design.userbg)
          setFlowBtn(response.data.design.flowBtn)
          setContactBtn(response.data.design.contactBtn)
          setInputBtn(response.data.design.inputBtn)

        }else{
          image = process.env.REACT_APP_MCHAT + 'media/' + response.data.image

          setProfilePic(image)
          setBotName(response.data.botName);
          setBg1(response.data.design.bg1)
          setBg2(response.data.design.bg2)
          setUserBg(response.data.design.userbg)
          setFlowBtn(response.data.design.flowBtn)
          setContactBtn(response.data.design.contactBtn)
          setInputBtn(response.data.design.inputBtn)
        }
      }
    })
  })

useEffect(() => {

      try {
        const resLink = `/api/mchat/public/chat/${chatId}`;
        mchatAxios.get(resLink).then( async (response) => {

        if(response.data.data){
          setFlow(response.data.data)
          flowRef.current = response.data.data

          if (!cookies.user) {
            await handleNoUser();
          } else {
            await handleExistingUser();
          }
        }else{
          console.log('Failed to fetch data')
        }
          
        })
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
  
  }, []);

  useEffect(() => {
    if (messages.length > 0) {
      updateMessage();
    }
  }, [messages]);

  useEffect(() => {
    if (currentFlow != 0) {
      updateCurrentFlow();
    }
  }, [currentFlow]);

  const restartSession = () => {
    const response = window.confirm('Are you sure to restart current chat and session?')
    if(response){

      removeCookie('user', { path: '/' })
      const delay = () => {
        window.location.reload()
      }
      setTimeout(delay, 1000)

    }

  }

  const updateMessage = () => {
    if(messages.length > 0 ){
    const data = { chat: messages};
    mchatAxios.put(`/api/mchat/public/chat/history/${chatId}/${cookies.user}`, data).catch((err) => {
     console.error(err)
    })
    if(currentFlowRef.current != 0){

    }
    }
  };

  let isUpdateFlow = false;

  const updateCurrentFlow = () => {

      if(currentFlow != null && !isUpdateFlow){
        isUpdateFlow = true;
      const data1 = { currentFlow: currentFlow};
      mchatAxios.put(`/api/mchat/public/chat/currentFlow/${chatId}/${cookies.user}`, data1).catch((err) => {
        console.error(err)
       })}

  }


  const handleNoUser = async () => {
    try {
      const resLink = `/api/mchat/public/getStart/${chatId}`;
      const response = await mchatAxios.get(resLink);
      if (response.status === 200) {
        setNameField(response.data.data.nameField);
        setSalutationField(response.data.data.salutationList.split(","));
        setGetStartFlow(response.data.data.trigger_flow_id);
        document.getElementById("welcome").click();
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Failed to fetch data", error);
    }
  };

  let isFlowFetched = false;

  const handleExistingUser = async () => {
    if(flowRef.current.length > 0 && !isFlowFetched){
      isFlowFetched = true;
    try {
      const resLink = `/api/mchat/public/chat/history/${chatId}/${cookies.user}`;
      const response = await mchatAxios.get(resLink);

      if (response.status === 200) {

        if (response.data.status === "exist chat"){
          setCurrentFlow(response.data.trigger)
          currentFlowRef.current = response.data.trigger
          setMessages(response.data.chat) 
          const flowId = currentFlowRef.current
          if(flowId > 0){

            const actions = flowRef.current
              .filter((item) => item.id === flowId) // Hanya ambil item yang match
              .map(item => item.actions); // Map kepada actions sahaja


          if(actions.length > 0){
            let buttons = []
            actions.flatMap(inner => inner.map(item => {
              buttons.push(item)
            }))


            if (buttons.length > 0){
              
              buttons.map((val, key) => {

                if(val.type == 'userinput'){
                  setShowInputBox(true)
                  setShowDivItem(true)
                  return userInput(val.inputName, val.triggerFlow)
                }else if(val.type == 'button'){
                  setShowInputBox(true)
                  setShowGrid(true)
                  setShowDivItem(true)
                  return flowButton(val.buttonContent, val.triggerFlow)
                }else if(val.type == 'contact'){
                  setShowInputBox(true)
                  setShowGrid(true)
                  setShowDivItem(true)
                  return contactButton(val.buttonContent, val.link)
                }

              })

            }

          }
          

            

          }

        } 
        else if (response.data.status === "null chat") {
          const trigger = response.data.trigger;
            await findFlow(trigger)
        }else if(response.data.status === "new chat"){
           await handleNoUser();
        }

      }else{
        console.error("Failed to fetch data");
      }

    } catch (error) {
      console.error("Failed to fetch data", error);
    }
  }
  };

  const findFlow = async (id) => {

    let updatetiming

    const executeFlow = () => {

      flowRef.current.map((val, key) => {
        if(val.id === id){
          if(val.blocks !== null){
          if(val.blocks.length > 0){
            val.blocks.map((item, index) => {
              const execMessage = () => {
                let content;
                if(item.category === 'Image'){
                  content = process.env.REACT_APP_MCHAT + 'media/' + item.content
                }else{
                  content = item.content
                }
                sendMessage(content, 'bot', item.category)
              }
              setTimeout(execMessage, 3000*(index+1));
    
      
              const timer = (3000 * val.blocks.length) + 1

              updatetiming = timer + 2000
      
      
              const showAction = () => {
                if(val.actions !== null){
                  if(val.actions[0].type === 'userinput'){
                    setShowInputBox(true)
                    setShowDivItem(true)
                  }else if(val.actions[0].type === 'button' || val.actions[0].type === 'contact'){
                    setShowInputBox(true)
                    setShowGrid(true)
                    setShowDivItem(true)
                  }
                }

              }
              
              setTimeout(showAction, timer);
            
            })
          }}
          if(val.actions !== null){
          if(val.actions.length > 0){
            let buttons = []
            for(var i=0; i<val.actions.length; i++){
              const singleItem = val.actions[i];
              buttons.push(singleItem)
            }
      
            if(buttons.length > 0){
              buttons.map((bval, bkey) => {
                if(bval.type === 'button'){
                   return flowButton(bval.buttonContent, bval.triggerFlow)
                }else if(bval.type === 'contact'){
                   return contactButton(bval.buttonContent, bval.link)
                }else if (bval.type === 'userinput'){
                   return userInput(bval.inputName, bval.triggerFlow)
                }
              })
            }
      
      
          }}
        }
       })
  
    }

    setCurrentFlow(id)
    setShowInputBox(false)
    setShowGrid(false)
    setShowDivItem(false)
    setDivs([]);
    executeFlow()
    setTimeout(updateMessage, updatetiming)
    setTimeout(updateCurrentFlow, 2000)
    

  }


const handleGetStart = (e) => {
  e.preventDefault();
  setGetStartButton(false)
  const delay = () => {
    setGetStartButton(true);
  }
  setTimeout(delay, 2000)
  const data = {name: name, salutation: salutation, triggerFlow: getStartFlow};
  mchatAxios.post(`/api/mchat/getStart/${chatId}`, data).then((response) => {
    if(response.status === 200){
      const expirationTime = new Date();
      expirationTime.setDate(expirationTime.getDate() + 1); // Tambah 1 hari

      setCookie('user', `${response.data.succ}`, { path: '/', expires: expirationTime });
      document.getElementById('closeWelcome').click();
      return findFlow(getStartFlow)
    }
  })
}

const handleButton = (e, name, flow) => {
  e.preventDefault();
  sendMessage(name, 'user', 'Text')
  findFlow(flow)
}

const sendMessage = (message, user, type) => {
  setMessages(messages => [...messages, { user: user, type: type , content:  message}]);
  if(user == 'user'){
    sendSound.play()
  }if(user == 'bot'){
    receiveSound.play()
  }
  }

    
const handleSend = (inputName, triggerFlow) => {
  const message = inputRef.current.value; // Ambil nilai terus dari inputRef
  if (message.trim()) {
    findFlow(triggerFlow)
    sendMessage(message, 'user', 'Text');
    setInputValues(""); // Reset inputValues jika perlu
    inputRef.current.value = ""; // Kosongkan textarea
  }
};


const flowButton = (name, flow) => {
  setDivs(prevDivs => [
      ...prevDivs, 
      <button 
          key={prevDivs.length} 
          className="choice-button my-1" 
          type="button" 
          onClick={(e) => handleButton(e, name, flow)}
      >
          {name}
      </button>
  ]);
}

const userInput = (inputName, triggerFlow) => {
  setDivs(prevDivs => [
    ...prevDivs,
    <>
      <textarea
        ref={inputRef}
        className="form-control my-2"
        placeholder={inputName}
        rows="2"
        onChange={(e) => {
          inputRef.current.value = e.target.value;  // Update dengan ref terus
          setInputValues(e.target.value);
        }}
      />
      <button
        className="send-button mx-2 my-2"
        onClick={() => handleSend(inputName, triggerFlow)}
      >
        <i className="bi bi-send-fill"></i>
      </button>
    </>
  ]);
};


const contactButton = (name, href) => {
  setDivs(prevDivs => [
      ...prevDivs, 
      <a  key={divs.length} className="contact-button my-1" href={href} target='_blank' rel="noopener noreferrer">{name}</a>
  ]);
}

const updatePopup = (content, e) => {
  e.preventDefault()
  setImgPop(content)
}


useEffect(() => {
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
}, [messages]);

useEffect(() => {
  // Filter children yang ada class 'input-box' sahaja
  const inputBoxChildren = React.Children.toArray(children).filter(child => {
    return child.props.className === 'test-input-box';
  });

  // Check if input-box children more than 5
  if (inputBoxChildren.length > 4) {
    setPaddingTop('0px');
  } else {
    setPaddingTop('0px');
  }
}, [children]);

// Dynamic styling
const containerStyle = {
  paddingTop: paddingTop,
  borderTop: '1px solid #ddd',
  marginBottom: '10px',
  display: 'flex',
  flexWrap: 'nowrap',
  overflowX: 'hidden',
  overflowXY: 'auto',
  overflowY: 'scroll',
  maxHeight: '20vh'
};

useEffect(() => {
if(cookies.user){
  mchatAxios.get(`/api/mchat/public/chat/user/${cookies.user}`).then((response) => {
    if(response.status === 200){
      console.log(response.data)
      setName(response.data.name)
      setSalutation(response.data.salutation)
    }
  })
}
}, [cookies.user])





  return (
  <div className='body d-flex justify-content-center'>
    <Helmet>
    <style>
      {`body { 
        background: ${`linear-gradient(90deg, ${bg1} 0%, ${bg2} 100%)`};
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
        margin: 0;
        font-family: Arial, sans-serif;
        }

        textarea{
          resize: none;
        }

        .form-control {
          flex: 1;
          border: 0;
          outline: 0;
      }

      .form-control:focus {
        box-shadow: none;
    }

      .test-input-box button, .test-input-box a {
          box-sizing: border-box;
          padding: 5px 5px;
          background-color: ${inputBtn};
          border: none;
          color: #fff;
          cursor: pointer;
          text-decoration: none;
      }

      .input-box button:hover {
        background-color: #0056b3;
    }

    .input-input-box button, .input-box a {
      box-sizing: border-box;
      padding: 10px 15px;
      background-color: ${inputBtn};
      border: none;
      color: #fff;
      cursor: pointer;
      flex: 0 0 auto;
  }

  .input-input-box button:hover {
    background-color: #0056b3;
}

    .message {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
      word-wrap: break-word;
  }

    .message.user .Text {
      background-color: ${userBg};
      color: #fff;
      padding: 10px 15px;
      border-radius: 10px 10px 0 10px;
      max-width: 70%;
      margin-left: auto;
  }

        .send-button {
          border-radius: 5px;
          border: none;
          color: ${inputBtn};
          cursor: pointer;
          height: 70%;
        }

        .choice-button {
          border-radius: 5px;
          padding: 2px;
          border: none;
          background-color: ${flowBtn};
          color: ${'#fff'};
          cursor: pointer;
          
        }

        .contact-button {
          border-radius: 5px;
          padding: 2px;
          border: none;
          background-color: ${contactBtn};
          color: ${'#fff'};
          cursor: pointer;
          text-align: center;
          
        }
        
        `}
    </style>
    </Helmet>
        <div  className="chat-container">
                <div className="header-box">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-10">
                                <div className="avatar">
                                    <img src={profilePic} className='profile-pic me-3'/><span className="align-middle me-1" style={{fontWeight:600, marginLeft: "1vh"}}>{botName}</span><span style={{fontSize: '10px'}}> 🟢</span>
                                </div>
                            </div>
                            <div className="col-2 text-end">
                                <div style={{marginTop: "1vh"}} className=""><button className='btn btn-sm btn-secondary' style={{borderRadius: '30px'}}  onClick={restartSession}><i class="bi bi-arrow-clockwise"></i></button></div>
                            </div>
        
                                
                        </div>
                    </div>
        
                </div>
                <div className="chat-box" ref={chatBoxRef}>
                  {messages.map((msg, index) => {

                    let message = msg.content;
                    const keyword = "{inputSalutation&Name}";
                    const replacement = `${salutation} ${name}`;

                    message = msg.content.replace(keyword, replacement)

                    return(
                    msg.type === 'Text' ? (
                      <div key={index} className={`message ${msg.user}`}>
                      <div className={msg.type} style={{whiteSpace:"pre-wrap"}}>
                        {message}
                      </div>
                  </div>
                    ) : msg.type === 'Image' ? (
                    <div key={index} className={`message ${msg.user}`}> 
                    <div className={msg.type}>
                      <img src={msg.content} width={'100%'} data-bs-toggle="modal" data-bs-target="#imagePopup" onClick={(e) => {updatePopup(msg.content, e)} } />
                      <div class="modal fade" id="imagePopup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                        <img src={imgPop} width={'100%'} />   
                        </div>
                      </div>
                      </div>
                    </div>) : msg.type === 'Video' ? (
                      <div key={index} className={`message ${msg.user}`}> 
                    <div className={msg.type}>
                    <ReactPlayer url={msg.content} width={'100%'}/>
                      </div></div>
                      ) : (<></>))
                      
                    
                  }
                 )}
                  
                </div>


                  {showInputBox === true ? (<>
                  
                    {showGrid === true ? (<>
                      <div className="test-input-box" style={containerStyle}>
                      <div className="d-grid gap-2 mt-2 mx-3 w-100" >
                        {showDivItem === true ? (<>{divs}</>): (<></>)}
                      </div>
                      </div>
                    </>) : (<>
                      <div className="input-input-box" style={{alignItems:'center'}}>
                      {showDivItem === true ? (<>{divs}</>): (<></>)}
                      </div>
                    </>)}

                             
                  </>) : (<></>)}

                
               
            </div>

<button id='welcome' type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" hidden>
  Welcome
</button>

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

        <button id='closeWelcome' type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" hidden></button>

      <div class="modal-body">
        <p style={{fontSize:'110%', whiteSpace:'pre'}}>{nameField}</p>
        <div class="minimal-form">
            <select class="minimal-select" onChange={(event) => {setSalutation(event.target.value)}}>
            <option>Select</option>
              {salutationField && salutationField.map((val, key) => {
                return(
                  <option key={key} value={val}>{val}</option>
                )
              })}
            </select>

            <input type="text" placeholder='Enter Name Here' class="minimal-input" onChange={(event) => {setName(event.target.value)}} />
          </div>
          <div className="d-grid">
            {getStartButton === true ? (<><button className='btn btn-sm btn-primary' onClick={handleGetStart}><i class="bi bi-box-arrow-in-right"></i> Submit</button></>) : (<>
              <button className='btn btn-sm btn-primary' disabled><i class="bi bi-box-arrow-in-right"></i> Submit</button></>)}
          </div>


      </div>
    </div>
  </div>
</div>


  </div>

  )
}

export default Mchat_Link