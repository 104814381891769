import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext';
import { mchatAxios } from '../../api/axios';
import {Helmet} from "react-helmet";
import ReactPlayer from 'react-player'

function Mchat_Data() {

const {chatId} = useParams();
const {user} = useAuthContext();
const [userList, setUserList] = useState([]);
const [viewIndex, setViewIndex] = useState(null)


useEffect(() => {
  mchatAxios.get(`/api/mchat/get/data/${chatId}`, {headers: {
    accessToken: user.token
  }}).then((response) => {
    if(response.status === 200){
      setUserList(response.data.user)
    }
  })
}, [user.token])

console.log(userList)
console.log(viewIndex)
  return (
    <div className='App'>
        <div className="container mt-3">
            <div className="row justify-content-center">
                <div className="col-lg-12 text-center">
                <h1 className="mt-4 header-title">MCHAT</h1>
                <div className="row justify-content-center">
                  <div className="col-lg-8 ">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to={'/mchat/chat'}>Chat List</Link></li>
                    <li class="breadcrumb-item active" aria-current="page">Chat Data</li>
                  </ol>
                    <div className="card">
                    <div class="table-responsive">
                      <table class="table table-hover ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Created At</th>
                            <th>View Chat</th>
                          </tr>
                        </thead>
                        <tbody>
                        {userList.map((item, key) => {
                          return (
                            <tr>
                              <td>{key+1}</td>
                              <td>{item.getStart.salutation} {item.getStart.name}</td>
                              <td>{new Date(item.user.createdAt).toLocaleString()}</td>
                              <td style={{alignContent:'center'}}><button className='btn btn-sm btn-dark' onClick={(e) =>{e.preventDefault(); setViewIndex(key+1)}} data-bs-toggle="modal" data-bs-target="#viewChat"><i class="bi bi-person-lines-fill"></i></button></td>
                            </tr>
                          )
                        })}
                        </tbody>
                      </table>
                    </div>
                    </div>
                  </div>
                </div>
                </div>
                </div>
                </div>


<div class="modal fade" id="viewChat" tabindex="-1" aria-labelledby="viewChatLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      {viewIndex && (<>
      {userList.map((item, key) => {
        if(key+1 === viewIndex){
          console.log('modal key:', key+1)
          return (
            <> 
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="viewChatLabel">{item.getStart.salutation} {item.getStart.name}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <Helmet>
          <style>
            {`.chat-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.chat-message {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 15px;
  position: relative;
  word-wrap: break-word;
}

.user1 {
  background-color: #5271ff;
  color: #ffffff;
  align-self: flex-start;
}

.user2 {
  background-color: #e2e3e5;
  align-self: flex-end;
}

.chat-message p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.4;
}

.time {
  font-size: 12px;
  color: #888;
  align-self: flex-end;
  margin-top: 5px;
}`}
          </style>
        </Helmet>
        {item.user.chat && (<>
          <div class="chat-container">

          {item.user.chat.map((item, key) => {
            if(item.user === 'bot'){
              return(<>
                <div class="chat-message user2">
                  {item.type === 'Text' ? (<><p>{item.content}</p></>) : item.type === 'Image' ? (<><img src={item.content} /></>) : item.type === 'Video' ? (<>
                    <ReactPlayer url={item.content} width={'auto'}/>
                  </>) : (<></>)}
                  
                </div>
              </>)
            }
            if(item.user === 'user'){
              return(<>
                <div class="chat-message user1">
                  <p>{item.content}</p>
                </div>
              </>)
            }
          })}

          </div>
        </>) }
        

      </div>
            </>
          )
        }
      })}
      </>)}

    </div>
  </div>
</div>
                </div>
  )
}

export default Mchat_Data